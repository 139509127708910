import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "keypeople" ];

  connect() {
    const element = this.keypeopleTarget
    const key_people_comma_sep = element.value
    const key_people = key_people_comma_sep.split(",");
    key_people.forEach(list_key_people)
    function list_key_people(person, index) {
        
      var output_area = document.getElementById("key_people_output");

      if (person !== ''){
        //svg.style.fill("currentColor");
        //svg.setAttribute("viewBox", "0 0 20 20");
        const person_icon = document.createElementNS('http://www.w3.org/2000/svg','svg')
        person_icon.setAttribute('class', 'flex-shrink-0 mr-2 h-5 w-5')
        const person_icon_path = document. createElementNS("http://www.w3.org/2000/svg", "path")
        person_icon_path.setAttribute('d', 'M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z');
        person_icon_path.setAttribute('fill', '#ceabed');
        person_icon.appendChild(person_icon_path)      
        output_area.appendChild(person_icon)

        document.createElement("p")
        var p_element = document.createElement("p");
        p_element.className += "mr-4 text-gray-800";

        var name_node = document.createTextNode(`${person}`);
        p_element.appendChild(name_node);
        
        output_area.appendChild(p_element);
        // "<svg class='flex-shrink-0 mr-2 h-5 w-5 text-tertiary-200' fill='currentColor' viewBox='0 0 20 20'><path d='M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z'/></svg>fuk "
      }
    }
    
  } 

}